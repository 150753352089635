// This file is generated by locala. Do not edit.
// Source: locala.yaml

export const strings = {
  "global.components.header.name": "Noa",
  "unsupported-screen.description": "We currently don't support small screens. Please use a larger device.",
  "maintenance.title": "Noa is sleeping",
  "maintenance.subtitle": "We are currently performing maintenance and will be back up shortly.",
  "login.intro.title": "Welcome to Noa",
  "login.button.login.with-google": "Continue with Google",
  "departments.title": "What's your department?",
  "departments.subtitle": "**NOA** provides a tailored experience based on your needs. The conversations are optimised for each department, their tasks and responsibilities.\n\nPlease make sure to select the appropriate department in order to have an optimal experience.",
  "profile.create.title": "Creating your profile",
  "profile.create.subtitle": "By telling noa about yourself, we’ll be able to give you more personalised & relevant answers in all your chat interactions with noa.",
  "profile.create.info": "The information you provide is private and will only be used to instruct noa in order to be more useful for you. You are in complete control of your information and you can edit or delete it at any time.",
  "profile.create.success.create": "Your user profile was successfully created",
  "profile.create.success.update": "Your user profile was successfully updated",
  "profile.create.submit.title": "Your noa profile is being updated",
  "profile.create.submit.subtitle": "We’re updating your noa profile.\\n\\nYou can continue chatting with noa and we will let you know when your profile is updated.",
  "profile.create.button.backToChat": "Back to chat",
  "profile.create.button.submit": "Save",
  "profile.create.button.next": "Continue",
  "profile.create.error": "Some error occured when user profile was updated",
  "profile.create.field.whoAreYou.title": "Who are you?",
  "profile.create.field.whoAreYou.placeholder": "Describe who you are",
  "profile.create.field.whatIsYourRole.title": "What is your role?",
  "profile.create.field.whatIsYourRole.placeholder": "Describe your role and what you do on a daily basis at work",
  "profile.create.field.commonTasks.title": "What are the most common tasks you do?",
  "profile.create.field.commonTasks.placeholder": "What are the most common tasks you do?",
  "profile.create.field.communication.title": "What tone of voice/communication style do you prefer?",
  "profile.create.field.communication.placeholder": "What tone of voice/communication style do you prefer?",
  "chats.intro.greeting": "HELLO, MY NAME IS NOA",
  "chats.intro.title": "How can I help today?",
  "chats.intro.message": "I can assist with a wide range of tasks, from research and proofreading to communication and brainstorming. With my help, you can save time and energy, allowing you to focus on the creative aspects of publishing. Let me show you how I can streamline your workflow and make your life easier.\nReady to see what I can do for you? **Let's chat.**",
  "chats.tooltips.image-upload": "Upload Image",
  "chats.tooltips.file-upload": "Upload File",
  "chats.tooltips.file-active": "Start a new chat to upload file / image",
  "chats.sidebar.history.title": "Conversation history",
  "chats.sidebar.history.chat-item.title-placeholder": "Name your chat",
  "chats.sidebar.history.chat-item.edit-char-max": "Must be < 50 characters",
  "chats.sidebar.actions.new-chat": "New chat",
  "chats.delete.title": "Are you sure you want to delete the chat?",
  "chats.delete.subtitle": "This will remove the chat from your history.\n\nThere is no way to recover this chat later on.",
  "chats.chat.input.placeholder": "Ask me anything",
  "chats.chat.message.rating.title": "How could the response be improved? What did we miss?",
  "chats.chat.message.rating.subtitle": "Help us make NOA better. Fill in the feedback form below.",
  "chats.chat.message.rating.button.submit": "Submit",
  "chats.chat.message.rating.submitted.title": "Thank you for your reply 💜",
  "chats.chat.message.rating.submitted.subtitle": "We are constantly improving NOA and your feedback means a lot for us.",
  "chats.chat.stop-generating": "Stop Generating",
  "chats.chat.files.attached.information": "Files can't be removed once a chat started.\n\nTo upload a new file, please start a new chat.",
  "chats.chat.error.title": "Something went wrong",
  "chats.chat.error.title-chrome-extension": "Oops! That’s a Bit Too Much Text!",
  "chats.chat.error.message-chrome-extension": "You’ve gone over the 5000 character limit. Trim it down a little, and let’s give it another go!",
  "chats.chat.error.message.unrecoverable": "We’re sorry, but an error has occurred. If the problem persists, please contact our support team for assistance.",
  "chats.chat.error.actions.new-chat": "Start new chat",
  "chats.chat.error.actions.contact-support": "Contact support",
  "files.upload.title": "Drag & drop your file here",
  "files.upload.button": "or upload",
  "files.upload.support-types.description": "Noa supports the following types",
  "files.upload.support-types.types": "PDF, DOCX",
  "files.upload.support-types.max": "MAX 20MB",
  "files.upload.attached.note": "Please note:\n\nLarge files may take longer to upload",
  "files.upload.attached.upload-button": "Upload file",
  "files.upload.finished.title": "Your file has been uploaded",
  "files.upload.finished.message": "And is ready to be used in the chat",
  "files.upload.finished.return-button": "Return to chat",
  "files.upload.error.max-files-reached.title": "Only 1 file per chat supported",
  "files.upload.error.max-files-reached.message": "Start a new chat if you want to upload a new file.\n\nSimply click the button below.",
  "files.upload.error.max-files-reached.button-text": "Start new chat",
  "files.upload.error.invalid-file-type.title": "Unsupported file format",
  "files.upload.error.invalid-file-type.message": "Please try any of the following formats:",
  "files.upload.error.invalid-file-type.button-text": "Try again",
  "files.upload.error.file-size-exceeded.title": "Document is too large",
  "files.upload.error.file-size-exceeded.message": "Your file contained too many characters, contained too many pages or was too large in file size.",
  "files.upload.error.file-size-exceeded.button-text": "Try again",
  "files.upload.error.file-processing-error.title": "File not processed",
  "files.upload.error.file-processing-error.message": "There was an error while trying to process the file. Please make sure the file is correct and try uploading it again. \n\nMake also sure that your file is not in protected/scan mode and contains text. \n\nIf the error persists, please [contact us](https://noa.chat/support).",
  "files.upload.error.file-processing-error.button-text": "Try again",
  "files.upload.error.file-corrupted.title": "File corrupted",
  "files.upload.error.file-corrupted.message": "Your file is corrupted. Please make sure your file is correct and try again.",
  "files.upload.error.file-corrupted.button-text": "Try again",
  "files.upload.error.file-no-text.title": "File Issue",
  "files.upload.error.file-no-text.message": "The uploaded PDF contains no readable text. Please check your file and upload a valid document.",
  "files.upload.error.file-no-text.button-text": "Try again",
  "files.upload.error.upload-failed.title": "File not uploaded",
  "files.upload.error.upload-failed.message": "There was an error while trying to upload the file. Please try uploading the file again.\n\nIf the error persists, please [contact us](https://noa.chat/support).",
  "files.upload.error.upload-failed.button-text": "Try again",
  "files.uploading.status.uploading": "Uploading your file",
  "files.uploading.status.processing": "Processing your file",
  "files.uploading.subtitle": "This may take a while,\n\nplease don’t close the window",
  "support.form.title": "Contact us",
  "support.form.subtitle": "Hi there! Our Noa team is committed to ensuring a great experience for all our users. We genuinely appreciate every bit of feedback and suggestions, and we're happy to answer any question that you might have.\n\nIf you've already gone through our [FAQs](https://sites.google.com/chaptr.xyz/noa-help-center/home/english#h.m0rzjsy206m6) or [Use Cases](https://sites.google.com/chaptr.xyz/noa-help-center/home/english/use-cases-for-noa), and still need help, please fill out this form below and we'll get back to you. Feel free to share any thoughts and ideas with us on how to improve Noa. 💜",
  "support.form.fields.name.label": "Name",
  "support.form.fields.name.placeholder": "What should we call you?",
  "support.form.fields.name.required": "Please provide your name",
  "support.form.fields.email.label": "Email",
  "support.form.fields.email.placeholder": "Which email address may we use to contact you?",
  "support.form.fields.email.invalid": "Please enter a valid email",
  "support.form.fields.email.required": "Please provide your email",
  "support.form.fields.category.label": "What made you get in touch?",
  "support.form.fields.category.placeholder": "Select a reason",
  "support.form.fields.category.invalid": "Please provide a valid reason",
  "support.form.fields.category.required": "Please select a reason",
  "support.form.fields.message.label": "Message",
  "support.form.fields.message.placeholder": "Kindly provide details about your issue, feedback or question here. If you wish, you can also include your chat conversation link, it might help us grasp your concern better.",
  "support.form.fields.message.required": "Please provide a description",
  "support.form.fields.message.max": "Please provide a description up to 5000 characters",
  "support.form.fields.chat.label": "Related chat thread",
  "support.form.fields.chat.placeholder": "Please select a chat (optional)",
  "support.form.actions.submit": "Submit",
  "support.form.success.title": "Thanks for Sharing Your Thoughts!",
  "support.form.success.message": "We're on it, and you'll hear from our team soon.\n\nIn the meantime, feel free to explore more ways to enhance your Noa experience or check out our [FAQs](https://sites.google.com/chaptr.xyz/noa-help-center/home/english#h.m0rzjsy206m6) for instant answers. 💜 ",
  "notifications.light-mode.title": "Try Light Mode Today!",
  "notifications.light-mode.message": "Welcome to a fresh chapter with NOA. Your input has sparked the bright new addition of Light Mode!\n\nPrefer Dark Mode? No worries! You can switch back anytime from your profile settings.",
  "notifications.light-mode.confirm": "Switch to Light Mode",
  "notifications.light-mode.cancel": "Stick with Dark Mode",
  "notifications.chrome-extension.title": "Install Now and Start Exploring!",
  "notifications.chrome-extension.message": "Enhance your browsing experience with the Noa Chrome Extension. Highlight text on any webpage, right-click to ask Noa, and get instant explanations and insights right within your browser.",
  "notifications.chrome-extension.confirm": "Install extension",
  "notifications.chrome-extension.cancel": "Maybe later",
  "notifications.personas.title": "Welcome to User Personas!",
  "notifications.personas.subheader": "Dive into a new way of interacting with Noa.",
  "notifications.personas.message": "We've introduced User Personas to tailor your experience more closely to your needs.",
  "notifications.personas.footer": "Start exploring this new feature by clicking 'Explore.'",
  "notifications.personas.confirm": "Explore",
  "notifications.personas.cancel": "Maybe later",
  "notifications.4o-model.title": "Upgrade to Noa's Model!",
  "notifications.4o-model.message": "We’ve upgraded the large language model behind Noa to GPT-4o, and the new version should help you work more efficiently and effectively, both with text and images.",
  "notifications.4o-model.confirm": "Let’s Go!",
  "admin.organisation.users.add.title": "Add users",
  "admin.organisation.users.add.emails.label": "Emails",
  "admin.organisation.users.add.emails.placeholder": "user@example.com\nuser1@example.com\nuser2@example.com",
  "admin.organisation.users.add.confirm": "Invite users",
  "admin.organisation.users.add.cancel": "Cancel",
  "account-menu.help-center": "Help center",
  "account-menu.support": "Contact us",
  "account-menu.theme.title": "Theme",
  "account-menu.theme.dark": "Dark",
  "account-menu.theme.light": "Light",
  "account-menu.theme.system": "System",
  "account-menu.admin": "Admin",
  "account-menu.chrome-extension": "Get Chrome Extension",
  "account-menu.log-out": "Log out",
  "tasks.custom-noa": "CUSTOM NOA",
  "error-boundary.title": "Something went wrong",
  "error-boundary.message": "We're sorry, but an error has occurred. If the problem persists, please contact our support team for assistance.",
  "error-boundary.contact-support": "If the problem persists, you can contact our support team using the button below.",
  "error-boundary.contact-support-btn": "Support",
  "error-boundary.logout-btn": "Logout",
  "error-boundary.refresh-btn": "Refresh Page"
} as const;