import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  collection,
  CollectionReference,
  FirestoreDataConverter,
  query,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Task } from "@noa/types/Task";
import { groupBy } from "lodash";
import { getDownloadURL, ref } from "firebase/storage";
import { db } from "~/integrations/firebase/firestore";
import { storage } from "~/integrations/firebase/storage";

export interface TasksByCategory {
  [category: string]: Task[];
}

interface TasksContextProps {
  tasksByCategory: TasksByCategory;
  allTasks: Task[];
  loading: boolean;
  error?: Error;
  getTaskById: (id: string) => Task | undefined;
  getTaskAvatar: (taskId?: string) => string | undefined;
  reference: CollectionReference<Task>;
}

const TasksContext = createContext<TasksContextProps | undefined>(undefined);

export const useTasks = () => {
  const context = useContext(TasksContext);
  if (!context) {
    throw new Error("useTasks must be used within a TasksProvider");
  }
  return context;
};

const taskConverter: FirestoreDataConverter<Task> = {
  toFirestore(task: Task): Task {
    return task;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Task {
    const data = snapshot.data(options);
    return { id: snapshot.id, ...data } as Task;
  },
};

export const TasksProvider = ({ children }: PropsWithChildren<{}>) => {
  const [resolvedAvatarLinks, setResolvedAvatarLinks] = useState<{
    [taskId: string]: string;
  }>({});

  const reference = collection(db, "tasks").withConverter(taskConverter);

  const userTasksQuery = query(reference);

  const [tasks = [], loading, error] = useCollectionData<Task>(userTasksQuery);

  const tasksByCategory = useMemo(() => groupBy(tasks, "category"), [tasks]);

  const tasksById = useMemo(() => {
    return new Map(tasks.map((task) => [task.id, task]));
  }, [tasks]);

  const fetchTaskAvatar = useCallback(
    async (id: string): Promise<void> => {
      const task = tasksById.get(id);

      if (task?.avatarGCSPath) {
        const storageRef = ref(storage, task.avatarGCSPath);
        try {
          const avatarUrl = await getDownloadURL(storageRef);
          setResolvedAvatarLinks((prevState) => ({
            ...prevState,
            [id]: avatarUrl,
          }));
        } catch (e) {
          console.error("Error fetching download URL:", e);
          throw e;
        }
      }
    },
    [tasksById, setResolvedAvatarLinks],
  );

  useEffect(() => {
    if (tasks.length > 0) {
      tasks.forEach((task) => {
        fetchTaskAvatar(task.id).then();
      });
    }
  }, [fetchTaskAvatar, tasks]);

  const getTaskById = useCallback(
    (id: string): Task | undefined => tasksById.get(id),
    [tasksById],
  );

  const getTaskAvatar = useCallback(
    (id?: string) => (id ? resolvedAvatarLinks[id] : undefined),
    [resolvedAvatarLinks],
  );

  const value = useMemo(() => {
    return {
      tasksByCategory,
      allTasks: tasks,
      loading,
      error,
      getTaskById,
      getTaskAvatar,
      reference,
    };
  }, [
    tasksByCategory,
    tasks,
    loading,
    error,
    getTaskById,
    getTaskAvatar,
    reference,
  ]);

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
};
