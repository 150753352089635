import { KeyboardEvent, PropsWithChildren, ReactNode } from "react";
import { useForm } from "react-hook-form";
import Textarea, { TextareaAutosizeProps } from "react-textarea-autosize";
import { PaperPlaneTilt, StopCircle } from "@phosphor-icons/react";
import { Button } from "./components";
import { cn } from "./utils";

interface ChatInputProps extends Omit<TextareaAutosizeProps, "onSubmit"> {
  actions?: ReactNode;
  isGenerating?: boolean;
  onStopGeneration?: () => void;
  onSubmit: (message: string) => Promise<void>;
  onUploadFile: () => void;
  disabled?: boolean;
}

export function ChatInput({
  className,
  placeholder,
  children,
  actions,
  isGenerating,
  onStopGeneration,
  disabled,
  onSubmit,
  onUploadFile,
  ...props
}: PropsWithChildren<ChatInputProps>) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<{ message: string }>({ defaultValues: { message: "" } });

  const handleFormSubmit = handleSubmit(async ({ message }) => {
    if (!disabled) {
      await onSubmit(message);
      reset();
    }
  });

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      void handleFormSubmit();
    }
  }

  return (
    <form
      onSubmit={handleFormSubmit}
      className={cn(
        "border rounded-xl p-6 pb-4 space-y-4 shadow-light bg-input-background border-input",
        className
      )}
    >
      <div className="flex space-x-3">
        <div className="text-lg drop-shadow-md shadow-pink-500">🧠</div>
        <Textarea
          {...register("message", { required: true })}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          data-testid="chat-input"
          className="flex-1 sm:min-h-[1.25rem] md:min-h-[4.25rem] outline-0 placeholder:text-placeholder bg-transparent max-h-[300px] resize-none"
          {...props}
        />
      </div>

      <div className="bg-input h-px w-full" />

      <div className="flex">
        {actions}

        <Button
          type={isGenerating ? "button" : "submit"}
          onClick={isGenerating ? onStopGeneration : undefined}
          disabled={!isGenerating && (!isValid || disabled)}
          className={cn(
            "ml-auto !text-white",
            isGenerating && "text-action-primary font-semibold"
          )}
          data-testid={
            isGenerating ? "chat-input-stop-button" : "chat-input-submit-button"
          }
        >
          {isGenerating ? (
            <div className="flex items-center">
              <StopCircle weight="bold" className="mr-2 h-5 w-5" />
              Stop Generating
            </div>
          ) : (
            <div className="flex items-center">
              <PaperPlaneTilt weight="fill" className="mr-2 h-4 w-4" />
              Send message
            </div>
          )}
        </Button>
      </div>

      {children}
    </form>
  );
}
