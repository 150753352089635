import React, { useMemo, useRef, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ChatSidebar, PageLayout } from "ui";
import { UsersThree } from "@phosphor-icons/react";
import { useChats } from "~/context/ChatsContext";
import { useLayout } from "~/context/LayoutContext";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { DeleteChatModal } from "~/components/Modal/DeleteChatModal";
import { useNotifications } from "~/context/NotificationsContext";
import { RenameChatModal } from "~/components/Modal/RenameChatModal";
import { getSidebarSections } from "~/utils/sidebar";
import useIsSmallDevice from "~/hooks/useIsSmallDevice";
import { cn } from "~/lib/utils";
import useOnClickOutside from "~/hooks/useOnClickOutside";
import { useApplication } from "~/context/ApplicationContext";
import { ChromeExtensionNotificationModal } from "~/components/Modal/ChromeExtensionNotificationModal";
import { useUser } from "~/context/UserContext";
import { PersonasNotificationModal } from "~/components/Modal/PersonasNotificationModal";
import { useTasks } from "~/context/TasksContext";
import { ErrorChatModal } from "~/components/Modal/ErrorChatModal";
import { InfoModal4o } from "~/components/Modal/InfoModal4o";

export default function MainLayout() {
  const navigate = useNavigate();
  const { id: chatId } = useParams();
  const [params] = useSearchParams();
  const { pathname } = useLocation();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(
    !!params.get("error"),
  );
  const { features } = useApplication();
  const { user } = useUser();

  const { isSmallDevice } = useIsSmallDevice();
  const { showSidebar, setShowSidebar } = useLayout();
  const { log } = useAnalytics();
  const { addNotification } = useNotifications();
  const {
    chats,
    loading,
    deleteChat,
    renameChat,
    renameChatId,
    deleteChatId,
    setDeleteChatId,
    setRenameChatId,
  } = useChats();

  const { tasksByCategory, loading: tasksLoading, getTaskAvatar } = useTasks();

  const onToggleSidebar = (shouldShowLeftDrawer: boolean) => {
    log({
      type: "sidebar_toggle_clicked",
      payload: {
        closed: !shouldShowLeftDrawer,
      },
    });

    setShowSidebar(shouldShowLeftDrawer);
  };

  const sections = useMemo(() => {
    if (loading) {
      return [];
    }

    const showChatSidebarItem = !!chatId || pathname.includes("tasks");

    return getSidebarSections(chats, getTaskAvatar, !showChatSidebarItem);
  }, [loading, chatId, pathname, chats, getTaskAvatar]);

  const customNoaSection = useMemo(() => {
    if (tasksLoading) {
      return null;
    }
    const items = Object.keys(tasksByCategory).map((key) => {
      switch (key) {
        case "user-persona":
          return (features.tasks?.personas ?? false)
            ? {
                id: "user-persona",
                title: "User personas",
                link: `/tasks/personas`,
                icon: <UsersThree size={24} color="#7255C7" />,
              }
            : null;
        default:
          return {
            id: key,
            title: "Custom Noa",
            link: `/`, // Navigate to the root page
          };
      }
    });

    const filteredItems = items.filter((item) => item !== null) as Array<
      NonNullable<(typeof items)[0]>
    >;

    return {
      title: "Custom Noa",
      items: filteredItems,
    };
  }, [tasksByCategory, tasksLoading, features.tasks]);

  const mainSections = useMemo(() => {
    if (customNoaSection === null || customNoaSection.items.length === 0) {
      return [];
    }

    return [customNoaSection];
  }, [customNoaSection]);

  const chatSidebarRef = useRef(null);

  useOnClickOutside(
    chatSidebarRef,
    () => isSmallDevice && onToggleSidebar(false),
  );

  const shouldDisplayChromeExtensionPopup =
    features.notifications?.chromeExtension &&
    user.notifications?.chromeExtension?.acknowledged === undefined;

  const shouldDisplayPersonasPopup =
    features.tasks?.personas &&
    user.notifications?.personas?.acknowledged === undefined;

  const shouldDisplay4oPopup =
    user.notifications?.model4o?.acknowledged === undefined;

  return (
    <div className="flex flex-col h-screen w-screen">
      <div
        data-sidebar-open={showSidebar}
        className="w-sidebar fixed top-0 left-0 bottom-0 flex transition opacity-0 data-[sidebar-open=true]:opacity-100"
      >
        <ChatSidebar
          mainSections={mainSections}
          sections={sections}
          className="scrollbar-trigger"
          onToggle={() => onToggleSidebar(!showSidebar)}
          onDelete={setDeleteChatId}
          onRename={setRenameChatId}
          isLoading={loading}
          ref={chatSidebarRef}
        />
      </div>

      <PageLayout
        data-sidebar-open={showSidebar}
        className={cn(
          "flex flex-1 m-2 overflow-hidden z-20 transition-all scrollbar-trigger",
          isSmallDevice && "data-[sidebar-open='true']:translate-x-sidebar",
          !isSmallDevice && "data-[sidebar-open='true']:ml-sidebar",
        )}
      >
        <Outlet />
      </PageLayout>

      {shouldDisplayChromeExtensionPopup && (
        <ChromeExtensionNotificationModal />
      )}

      {shouldDisplay4oPopup && <InfoModal4o />}

      {shouldDisplayPersonasPopup && <PersonasNotificationModal />}

      {showErrorModal && <ErrorChatModal onClose={setShowErrorModal} />}

      <RenameChatModal
        id={renameChatId}
        show={!!renameChatId}
        onClose={() => setRenameChatId(undefined)}
        onSubmit={async (title) => {
          if (!renameChatId) {
            return;
          }

          await renameChat(renameChatId, title);
          setRenameChatId(undefined);
        }}
      />

      <DeleteChatModal
        show={!!deleteChatId}
        onClose={() => setDeleteChatId(undefined)}
        onDelete={async () => {
          if (!deleteChatId) {
            return;
          }

          await deleteChat(deleteChatId);
          addNotification("Your chat has been deleted.", "success");
          setDeleteChatId(undefined);
          navigate("/");
        }}
      />
    </div>
  );
}
