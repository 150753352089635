import React from "react";
import { Link } from "react-router-dom";
import { Button } from "ui";
import { Text } from "~/components/Text";
import { NoaErrorIcon } from "~/assets/icons/NoaErrorIcon";
import { cn } from "~/lib/utils";
import { auth } from "~/integrations/firebase/auth";

interface ErrorBoundaryHeroProps {
  className?: string;
}

export function ErrorBoundaryHero({ className }: ErrorBoundaryHeroProps) {
  const handleRefresh = () => {
    window.location.reload();
  };

  const handleSignOut = async () => {
    await auth.signOut();
    handleRefresh();
  };

  return (
    <main
      className={cn("flex flex-col items-center gap-10 mt-[100px]", className)}
    >
      <NoaErrorIcon height="200" width="250" />
      <p className="text-2xl font-semibold">
        <Text id="error-boundary.title" />
      </p>

      <p className="text-center">
        <Text id="error-boundary.message" />
        <br />
        <Text id="error-boundary.contact-support" />
      </p>

      <div className="flex gap-4">
        <Link to="/support">
          <Button>
            <Text id="error-boundary.contact-support-btn" />
          </Button>
        </Link>

        <Button variant="secondary" onClick={handleSignOut}>
          <Text id="error-boundary.logout-btn" />
        </Button>

        <Button variant="secondary" onClick={handleRefresh}>
          <Text id="error-boundary.refresh-btn" />
        </Button>
      </div>
    </main>
  );
}
