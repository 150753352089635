import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import { cn } from "./utils";
import { Button, Tooltip, TooltipContent, TooltipTrigger } from "./components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  oneDark,
  oneLight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { Check, Copy } from "@phosphor-icons/react";
import rehypeExternalLinks from 'rehype-external-links'

interface Props {
  content: string;
  className?: string;
  theme?: "dark" | "light";
}

async function copyContent(content: string) {
  try {
    await navigator.clipboard.writeText(content);
    return true;
  } catch (error) {
    console.error('Failed to copy content:', error);
    return false;
  }
}

interface CodeProps {
  language?: string;
  code: string;
  theme?: "light" | "dark";
}

function Code({ language, code, theme }: CodeProps) {
  const style = theme === "dark" ? oneDark : oneLight;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;

    const timeout = setTimeout(() => setCopied(false), 3000);
    return () => clearTimeout(timeout);
  }, [copied]);

  const handleCopy = async () => {
    const success = await copyContent(code);
    if (success) {
      setCopied(true);
    }
  };

  return (
    <div className="border border-border rounded-md overflow-hidden">
      <div className="px-3 text-sm flex items-center h-10">
        {language && (
          <span className="font-medium text-content-secondary">{language}</span>
        )}

        <Tooltip open={copied ? true : undefined}>
          <TooltipTrigger asChild>
            <Button
              size="icon"
              variant="ghost"
              className="ml-auto p-0 w-4 h-4 text-icons hover:bg-transparent"
              onClick={handleCopy}
            >
              {copied ? (
                <Check className="w-4 h-4 text-green-600" />
              ) : (
                <Copy className="w-4 h-4 text-icons" />
              )}
            </Button>
          </TooltipTrigger>

          <TooltipContent>
            <span className="uppercase font-medium text-sm tracking-wide">
              {copied ? "Copied to clipboard" : "Copy"}
            </span>
          </TooltipContent>
        </Tooltip>
      </div>

      <SyntaxHighlighter
        language={language}
        style={style}
        wrapLongLines
        customStyle={{
          margin: 0,
          borderRadius: 0,
        }}
      >
        {code.trim()}
      </SyntaxHighlighter>
    </div>
  );
}

export function Markdown({ content, className, theme }: Props) {
  return (
    <ReactMarkdown
      className={cn("prose dark:prose-invert", className)}
      allowedElements={[
        "p",
        "b",
        "i",
        "strong",
        "li",
        "ol",
        "ul",
        "em",
        "a",
        "code",
        "img",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "table",
        "blockquote",
      ]}      
      components={{
        code({className, children}) {
          const match = /language-(\w+)/.exec(className || '');
          const language = match?.[1].includes("markdown") ? undefined : match?.[1];
          const code = String(children);

          return <Code code={code} language={language} theme={theme} />;
        },
      }}
      unwrapDisallowed
      rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}
    >
      {content}
    </ReactMarkdown>
  );
}