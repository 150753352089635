import {
  LDContext,
  useFlags,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { useCallback, useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import * as Sentry from "@sentry/react";
import {
  Context,
  ContextKey,
  INITIAL_CONTEXT,
} from "~/integrations/launch-darkly";
import { parseFeatureFlags } from "~/integrations/launch-darkly/utils";

export interface LaunchDarklyFeatureFlags {
  // Feature flags
  rolloutChatImageUpload: boolean;
  rolloutChromeExtensionNotification: boolean;
  rolloutTasksPersonas: boolean;
  rolloutChatPdfDownload: boolean;

  // Operational flags
  operationalMaintenance: boolean;

  // Theme
  rolloutThemeNotification: boolean;
}

export function useLaunchDarkly() {
  const client = useLDClient();
  const flags = useFlags<LaunchDarklyFeatureFlags>();

  const [contexts, setContexts] = useState<LDContext>(INITIAL_CONTEXT);

  useEffect(() => {
    if (!client) {
      return;
    }

    const currentContext = client.getContext();

    if (isEqual(currentContext, contexts)) {
      return;
    }

    client.identify(contexts).catch((error) => {
      console.error(
        "Failed to identify contexts in LaunchDarkly client",
        error,
      );
      Sentry.captureException(error);
    });
  }, [contexts, client]);

  const features = parseFeatureFlags(flags);

  const setContext = useCallback(
    <K extends ContextKey, C extends Context[K]>(key: K, context: C) => {
      setContexts((current) => ({
        ...current,
        [key]: context,
      }));
    },
    [],
  );

  const clearContext = useCallback(() => {
    setContexts(INITIAL_CONTEXT);
  }, []);

  return {
    features,
    setContext,
    clearContext,
  };
}

export type FeatureFlags = ReturnType<typeof parseFeatureFlags>;
