import { FileArrowDown, SpinnerGap, Check } from "@phosphor-icons/react";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "ui/src";
import React, { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import * as Sentry from "@sentry/react";
import { functions } from "~/integrations/firebase/functions";
import { useNotifications } from "~/context/NotificationsContext";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { useUser } from "~/context/UserContext";
import { cn } from "~/lib/utils";

interface Props {
  chatId: string;
}

type ButtonState = "DEFAULT" | "GENERATING" | "SUCCESS";

const buttonIcon: Record<ButtonState, React.ReactNode> = {
  DEFAULT: (
    <FileArrowDown
      data-testid="default-icon"
      height="20px"
      width="20px"
      className="fill-content-secondary flex-shrink-0 opacity-1 group-hover:fill-content-primary"
    />
  ),
  GENERATING: (
    <SpinnerGap
      data-testid="generating-icon"
      height="20px"
      width="20px"
      className="animate-spin duration-500 fill-content-secondary flex-shrink-0 opacity-1 group-hover:fill-content-primary"
    />
  ),
  SUCCESS: (
    <Check
      data-testid="success-icon"
      height="20px"
      width="20px"
      fill="#05A551"
      className="flex-shrink-0 opacity-1"
    />
  ),
};

interface ExportChatUrlResponse {
  data: {
    url: string;
  };
}

const EXPORT_CHAT_OPTIONS = [
  { type: "pdf", label: "Export as .PDF" },
  { type: "docx", label: "Export as .DOCX" },
];

const ExportChatButton = ({ chatId }: Props) => {
  const [buttonState, setButtonState] = useState<ButtonState>("DEFAULT");
  const [open, setOpen] = useState(false);
  const { addNotification } = useNotifications();
  const { user } = useUser();
  const { log } = useAnalytics();

  const handleSaveToFile = (downloadUrl: string) => {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "chat.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClick = async (type: string) => {
    setButtonState("GENERATING");

    try {
      const generateUrl = httpsCallable<
        { chat_id: string; type: string },
        ExportChatUrlResponse
      >(functions, "generate_chat_pdf_download_url");
      const result: any = await generateUrl({
        chat_id: chatId,
        type,
      });
      const downloadUrl = result.data.url;

      if (downloadUrl) {
        handleSaveToFile(downloadUrl);
        setButtonState("SUCCESS");

        log({
          type: "chat_pdf_export",
          payload: {
            organisationId: user.organisationId,
            userId: user.userId,
          },
        });
      } else {
        throw new Error("No download URL provided");
      }
    } catch (error: any) {
      console.error("Error generating PDF download URL:", error);
      Sentry.captureException(error);
      addNotification(
        "Failed to download PDF. Please try again.",
        "error",
        undefined,
        5000,
      );
      setButtonState("DEFAULT");
    }
  };

  useEffect(() => {
    if (buttonState === "SUCCESS") {
      const timer = setTimeout(() => {
        setButtonState("DEFAULT");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [buttonState]);

  return (
    <DropdownMenu onOpenChange={(o) => setOpen(o)}>
      <DropdownMenuTrigger asChild>
        <Button
          disabled={buttonState === "GENERATING" || buttonState === "SUCCESS"}
          variant="outline"
          className={cn(
            "transition-all transform duration-300 group mr-4 overflow-hidden h-12 w-12 disabled:opacity-1 focus:outline-none",
            buttonState === "DEFAULT" && "hover:w-[91px]",
            open && "w-[91px]",
            buttonState === "GENERATING" && "w-[91px]",
            buttonState === "SUCCESS" && "w-12",
          )}
        >
          {buttonIcon[buttonState]}
          <div
            className={cn(
              "text-sm text-content-secondary translate-x-16 transition-all transform duration-300 w-0 group-hover:text-content-primary whitespace-nowrap",
              open && "text-content-primary translate-x-1 w-[41px]",
              buttonState === "DEFAULT" &&
                "group-hover:translate-x-1 group-hover:w-[41px]",
              buttonState === "GENERATING" && "translate-x-1 w-[41px]",
              buttonState === "SUCCESS" && "hidden w-0",
            )}
          >
            Export
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="text-sm text-content-secondary transition-all transform duration-300 group-hover:text-content-primary whitespace-nowrap">
        {EXPORT_CHAT_OPTIONS.map((exportChatOption) => (
          <DropdownMenuItem
            key={exportChatOption.type}
            onClick={() => handleClick(exportChatOption.type)}
          >
            {exportChatOption.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ExportChatButton;
