import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  Input,
  Label,
} from "ui";
import React, { useEffect, useState } from "react";
import {
  CloudArrowUp as UploadSVG,
  X as CloseSVG,
} from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { DocumentReference } from "firebase/firestore";
import { FileUploadErrorCode } from "src/components/FileUpload";
import { useFiles } from "~/context/FilesContext";
import { FileUploadError } from "~/components/FileUpload/FileUploadError";
import UploadProgress from "~/components/ImageUpload/UploadProgress";

interface Props {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;

  onUploadFileSuccess(fileId: string): Promise<void>;
}

interface FormValues {
  image: FileList | null;
}

// TODO: Add analytics
const UploadImageModal = ({
  isOpen,
  setIsModalOpen,
  onUploadFileSuccess,
}: Props) => {
  const { createFileForUploading } = useFiles();
  const [ref, setRef] = useState<DocumentReference>();
  const [error, setError] = useState<FileUploadErrorCode>();
  const form = useForm<FormValues>();

  const image = form.watch("image")?.[0];

  useEffect(() => {
    const subscription = form.watch(async (values) => {
      const file = values.image?.[0];
      if (!file) return;

      try {
        const fileRef = await createFileForUploading(file.name, file.type);
        setRef(fileRef);
      } catch (e) {
        setError(e);
      }
    });

    return () => subscription.unsubscribe();
  }, [form.watch, createFileForUploading, form]);

  const onClear = () => {
    form.reset({ image: null });
    setError(undefined);
    setRef(undefined);
  };

  return (
    <div className="flex flex-col">
      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            setIsModalOpen(false);
          }
        }}
      >
        <DialogContent
          hideClose
          className="!p-0 overflow-hidden gap-0"
          data-testid="upload-image-chat-modal"
        >
          <div className="bg-secondary px-6 py-5 flex flex-row justify-between items-center">
            <DialogTitle className="text-content-secondary">
              Upload image
            </DialogTitle>
            <DialogClose>
              <CloseSVG />
              <span className="sr-only">Close</span>
            </DialogClose>
          </div>

          <DialogDescription className="px-12 py-10 flex flex-row items-center justify-between">
            {ref && image ? (
              <UploadProgress
                docRef={ref}
                image={image}
                onUploadFileSuccess={onUploadFileSuccess}
                setError={setError}
                setIsModalOpen={setIsModalOpen}
              />
            ) : (
              <>
                <span className="flex flex-row items-center">
                  <UploadSVG width={40} height={40} />
                  <span className="ml-6">
                    PNG, JPG, JPEG files supported <br /> (Max 20Mb)
                  </span>
                </span>
                <Label
                  htmlFor="image-upload-input"
                  className="cursor-pointer bg-white py-3 px-10 border border-gray-300 rounded shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                >
                  Select File
                  <Input
                    id="image-upload-input"
                    data-testid="image-upload-input"
                    accept="image/jpg, image/jpeg, image/png"
                    type="file"
                    className="hidden"
                    {...form.register("image")}
                  />
                </Label>
              </>
            )}
          </DialogDescription>
        </DialogContent>
      </Dialog>

      {error && (
        <FileUploadError
          error={error}
          onClear={onClear}
          onClose={onClear}
          onNewChat={() => null}
        />
      )}
    </div>
  );
};

export default UploadImageModal;
