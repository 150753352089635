import React, { PropsWithChildren, useMemo } from "react";
import { File } from "@noa/types";
import { ChatInput as Input } from "ui";
import { ChatFiles } from "~/components/Chat/ChatFiles/ChatFiles";
import ImageUploadButton from "~/components/ImageUpload/ImageUploadButton";
import { useApplication } from "~/context/ApplicationContext";
import FileUploadButton from "~/components/FileUpload/FileUploadButton";

interface ChatInputProps {
  disabled?: boolean;
  activeFile?: File;
  showFileModal?: boolean;
  isGenerating?: boolean;
  showActions?: boolean;

  onStopGeneration?(): void;

  onUploadFile(): void;

  onUploadFileSuccess(fileId: string): Promise<void>;

  onSubmit(message: string): Promise<void>;

  onRemoveFile?(): void;
}

export enum UploadedFile {
  FILE,
  IMAGE,
}

export function ChatInput({
  disabled,
  activeFile,
  showFileModal,
  isGenerating,
  onSubmit,
  onUploadFile,
  onUploadFileSuccess,
  onStopGeneration,
  onRemoveFile,
  showActions = true,
}: PropsWithChildren<ChatInputProps>) {
  const { features } = useApplication();

  const fileType: UploadedFile | undefined = useMemo(() => {
    if (!activeFile) return undefined;

    if (activeFile?.fileType.includes("image")) {
      return UploadedFile.IMAGE;
    }

    return UploadedFile.FILE;
  }, [activeFile]);

  return (
    <Input
      onSubmit={onSubmit}
      disabled={disabled}
      onUploadFile={onUploadFile}
      placeholder="What's on your mind?"
      className="mx-auto max-w-chat"
      isGenerating={isGenerating}
      onStopGeneration={onStopGeneration}
      actions={
        showActions && (
          <div>
            <FileUploadButton
              fileType={fileType}
              onUploadFile={onUploadFile}
              showFileModal={showFileModal}
            />
            {features.chat.image.upload && (
              <ImageUploadButton
                onUploadFileSuccess={onUploadFileSuccess}
                hasUploadedFile={fileType === UploadedFile.IMAGE}
              />
            )}
          </div>
        )
      }
    >
      {showFileModal && (
        <ChatFiles
          activeFile={activeFile}
          onToggle={onUploadFile}
          onSuccess={onUploadFileSuccess}
          onRemoveFile={onRemoveFile}
        />
      )}
    </Input>
  );
}
