import React, { useEffect, useState } from "react";
import { FileErrorCode, FileStatus } from "@noa/types";
import { sumBy } from "lodash";
import { File as FileIcon } from "@phosphor-icons/react/dist/csr/File";
import { Check, File } from "@phosphor-icons/react";
import { Progress } from "ui";
import { DocumentReference } from "firebase/firestore";
import * as Sentry from "@sentry/react";
import { useFile } from "~/hooks/useFile";
import { FileUploadErrorCode } from "~/components/FileUpload/FileUploadModal";

interface FileUploadProgressProps {
  file: File;
  reference: DocumentReference;

  setError(error: FileUploadErrorCode): void;

  onSuccess(fileId: string): Promise<void>;
}

export function FileUploadProgress({
  file: fileToUpload,
  reference,
  setError,
  onSuccess,
}: FileUploadProgressProps) {
  const { file, uploadFileToStorage } = useFile(reference.id);
  const [progress, setProgress] = useState(0);
  const [bytesProgress, setBytesProgress] = useState(0);

  useEffect(() => {
    const stages: FileStatus[] = [FileStatus.UPLOADING, FileStatus.UPLOADED];

    if (!file?.status) {
      return setProgress(0);
    }

    // Don't set the status for errors as it's handled by another screen, and it animates before it updates the UI
    if (file.status === FileStatus.ERROR) {
      return;
    }

    const total = sumBy(stages, (stage) => {
      const index = stages.indexOf(stage);
      const current = stages.indexOf(file.status);

      // Show previous stages as complete
      if (current > index) {
        return 100;
      }

      // Animate as the bytes are uploaded
      if (current === index && stage === FileStatus.UPLOADING) {
        return bytesProgress;
      }

      // Show current stage as almost complete
      if (current === index) {
        return 90;
      }

      return 0;
    });

    setProgress(total / stages.length);
  }, [file?.status, bytesProgress]);

  useEffect(() => {
    const controller = new AbortController();

    async function upload() {
      try {
        // Use firebase storage
        const result = await uploadFileToStorage(
          fileToUpload,
          (uploadProgress) => {
            // Math.max required as sometimes the progress goes backwards?
            setBytesProgress((current) => Math.max(current, uploadProgress));
          },
          controller.signal,
        );

        if (result.status === "ERROR") {
          throw new Error(result.message);
        }
      } catch (e) {
        console.error("Failed to upload file");
        console.error(e);
        Sentry.captureException(e);

        setError("FILE_UPLOAD_FAILED");
      }
    }

    upload().then();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToUpload, setError]);

  useEffect(() => {
    if (file?.status !== FileStatus.ERROR) {
      return;
    }

    if (file?.status === FileStatus.ERROR) {
      switch (file?.error?.errorCode) {
        case FileErrorCode.ERR_PROCESSING_FILE:
          setError("FILE_PROCESSING_ERROR");
          return;
        case FileErrorCode.ERR_FILE_CORRUPTED:
          setError("FILE_CORRUPTED");
          return;
        case FileErrorCode.ERR_NO_TEXT:
          setError("FILE_NO_TEXT");
          return;
        default:
          setError("FILE_PROCESSING_ERROR");
          return;
      }
    }

    setError("FILE_UPLOAD_FAILED");
  }, [file?.status, file?.error, setError]);

  useEffect(() => {
    if (file?.status !== FileStatus.PROCESSED) {
      return;
    }

    onSuccess(file.id).then();
  }, [file?.id, file?.status, onSuccess]);

  if (file?.status === FileStatus.PROCESSED) {
    return (
      <div className="border p-4 rounded-lg space-x-3 flex items-center">
        <FileIcon className="h-10 w-10 text-icons-50" />

        <div className="space-y-2 leading-tight flex-1 grid grid-cols-1">
          <div className="inline-flex">
            <div className="font-medium truncate text-content-primary">
              {file.fileName}
            </div>
            <Check className="text-check h-5 ml-1" />
          </div>
          <div className="text-sm text-content-secondary">
            This file is active in this chat
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="border p-4 rounded-lg space-x-3 flex items-center">
      <FileIcon className="h-10 w-10 text-icons-50" />

      <div className="space-y-2 leading-tight flex-1 grid grid-cols-1">
        <div className="font-medium truncate text-content-primary">
          {file?.fileName}
        </div>
        <div className="text-sm text-content-secondary">
          Uploading in progress
        </div>
      </div>

      <div className="ml-auto">
        <Progress progress={progress} className="h-12 w-12" />
      </div>
    </div>
  );
}
