import React, { useState } from "react";
import { Text } from "@noa/locales";
import { DocumentReference } from "firebase/firestore";
import { Button } from "ui";
import { File, Upload } from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import { useFiles } from "~/context/FilesContext";
import { FileUploadErrorCode } from "~/components/FileUpload/FileUploadModal";
import { FileUploadProgress } from "~/components/FileUpload/FileUploadProgress";

interface UploadingScreenProps {
  files: File[];

  setError(error: FileUploadErrorCode): void;

  onSuccess(fileId: string): Promise<void>;
}

export function UploadingScreen({
  files,
  setError,
  onSuccess,
}: UploadingScreenProps) {
  const { createFileForUploading } = useFiles();

  const [reference, setReference] = useState<DocumentReference>();

  const onUpload = async () => {
    if (!files.length) {
      console.error("No files to upload");
      return;
    }

    // Only support one file for now
    const [file] = files;

    try {
      const ref = await createFileForUploading(file.name, file.type);

      setReference(ref);
    } catch (e) {
      console.error("Failed to create file upload request");
      console.error(e);
      Sentry.captureException(e);

      setError("FILE_UPLOAD_FAILED");
    }
  };

  if (reference) {
    return (
      <FileUploadProgress
        file={files[0]}
        reference={reference}
        setError={setError}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <div className="border-dashed border-2 rounded-lg pt-8 pb-6 px-4 flex flex-col items-center">
      <div className="flex flex-col items-center space-y-6">
        <File className="h-10 w-10 text-icons-50" />

        <div className="space-y-4 text-center grid grid-cols-1">
          <div className="text-lg truncate text-content-secondary">
            {files.map((file) => file.name).join(", ")}
          </div>
          <div className="text-content-tertiary text-sm">
            Larger files may take longer to upload
          </div>
        </div>
      </div>

      <Button
        onClick={onUpload}
        className="mt-10 px-8"
        data-testid="file-upload-upload-button"
        type="button"
      >
        <Upload weight="fill" className="h-5 w-5 mr-2" />
        <Text id="files.upload.attached.upload-button" />
      </Button>
    </div>
  );
}
