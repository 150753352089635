import React, { useEffect, useState } from "react";
import {
  ArrowClockwise,
  Check,
  Copy,
  ThumbsDown,
  ThumbsUp,
} from "@phosphor-icons/react";
import { RatingType } from "@noa/types";
import { ChatMessageAction } from "./ChatMessageAction";
import { cn } from "~/lib/utils";

interface ChatMessageActionsProps {
  message: any;
  isReady: boolean;
  isLastMessage: boolean;

  onRegenerate(): void;

  onLike(): void;

  onDislike(): void;

  onCopy(): Promise<boolean>;
}

export function ChatMessageActions({
  message,
  isReady,
  isLastMessage,
  onRegenerate,
  onLike,
  onDislike,
  onCopy,
}: ChatMessageActionsProps) {
  const hasContent = Boolean(message.content);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) {
      return;
    }

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  return (
    <div className="flex items-center space-x-2">
      {hasContent && (
        <ChatMessageAction
          title={copied ? "Copied to Clipboard" : "Copy"}
          showTooltip={copied || undefined}
          onClick={async () => {
            if (await onCopy()) {
              setCopied(true);
            }
          }}
          className={cn(copied && "text-green-600")}
          icon={copied ? Check : Copy}
        />
      )}

      {message.role === "AI" && hasContent && (
        <ChatMessageAction
          title="Good response"
          className={cn(
            message?.rating?.value === RatingType.LIKE && "text-action-primary",
          )}
          onClick={onLike}
          disabled={message?.rating?.value}
          icon={ThumbsUp}
          iconWeight={
            message?.rating?.value === RatingType.LIKE ? "fill" : "regular"
          }
        />
      )}

      {message.role === "AI" && hasContent && (
        <ChatMessageAction
          title="Bad response"
          className={cn(
            message?.rating?.value === RatingType.DISLIKE &&
              "text-action-primary",
          )}
          onClick={onDislike}
          disabled={message?.rating?.value}
          icon={ThumbsDown}
          iconWeight={
            message?.rating?.value === RatingType.DISLIKE ? "fill" : "regular"
          }
        />
      )}

      {message.role === "AI" && isLastMessage && isReady && (
        <ChatMessageAction
          title="Regenerate"
          onClick={onRegenerate}
          icon={ArrowClockwise}
        />
      )}
    </div>
  );
}
